<template>
    <div class="ranking">
        <div class="material_screen">
            <div class="mian">
                <div class="inner_nav clearfix">
                    <div class="fl inner_nav_l">当前位置：<span>每日一字</span> > 每日上榜</div>
                    <div class="fr inner_nav_r">共 <span>{{total}}</span> 个上榜作品</div>
                </div>
                <router-link class="inner_banner" :to="{name:banner.url,query:{id:banner.links_id!=0?banner.links_id:''} }">
                    <img :src="banner.thumb" alt="" />
                </router-link>
            </div>
        </div>
        <div class="mian">
            <div class="list">
                <div class="ranking_ul clearfix" v-if="list.length>0">
                    <!-- wow animate__animated animate__fadeInUp -->
                    <div class="fl daily_li " :style="{animationDelay:0.1*index+'s'}" v-for="(item,index) in list" :key="index" @click="toDetail(item.id)">
                        <div class="ranking_li_box">
                            <img v-lazy="item.thumb" alt="">
                        </div>
                        <div class="ranking_li_name">{{item.title}}</div>
                    </div>
                </div>
                <el-empty v-else description="暂无数据"></el-empty>
                <page v-if="total>12" :page="page" :limit="limit" :total="total" ref="page"></page>
            </div>
        </div>
        <!-- 侧边导航 -->
        <sidebar ref="downtotal"></sidebar>
    </div>
</template>

<script>
// import { WOW } from 'wowjs'
import sidebar from '../components/sidebar.vue'
import page from '../components/page.vue'
export default {
    name: "ranking",
    components:{
        sidebar,
        page
    },
    data(){
        return{
            banner:{},//banner

            onlist_total:0,//作品总数
            list:[],

            // 分页
            total:0,//总数
            page:1,//页数
            limit:12,//每页个数

            // 收藏参数
            type:'',//类型
            collId:'',//收藏id
        }
    },
    mounted(){
        this.$parent.routerIndex = 6;
        this.$parent.showIndex = 1;
        // new WOW().init();
        this.bannerOneban();
        this.listFun()
    },
    methods:{
        // 获取内部banner、 
		bannerOneban(){
			var that = this;
            this.$api.POST(this.$face.bannerOneban,{location:4},function(res){
                switch(res.data.links) {
                    case 0:
                        res.data.url = '';
                        break;
                    case 1:
                        res.data.url = 'material';
                        break;
                    case 2:
                        res.data.url = 'video';
                        break;
                    case 3:
                        res.data.url = 'deformation';
                        break;
                    case 4:
                        res.data.url = 'ranking';
                        break;
                    case 5:
                        res.data.url = 'dailyWord';
                        break;
                    case 6:
                        res.data.url = 'fontSearch';
                        break;
                    case 7:
                        res.data.url = 'dailyWordUpload';
                        break;
                    case 8:
                        res.data.url = 'vip';
                        break;
                    case 9:
                        res.data.url = 'project';
                        break;
                    case 10:
                        res.data.url = 'rankingDetail';
                        break;
                    case 11:
                        res.data.url = 'projectDetail';
                        break;
					case 12:
						res.data.url = 'downLoad';
						break;
                }
                that.banner = res.data;
                
            })
		},

        listFun(){
            var that = this;
            var params ={
                page: this.page,
                limit: this.limit,
            }
            this.$api.POST(this.$face.onlistIndex,params,function(res){
                that.list = res.data.list;
                that.total = res.data.total;
            })
        },
        
        // 查看详情
        toDetail(id){
            localStorage.setItem('clearAll',2)
            //this.$router.push({name:'rankingDetail',query:{id:id}})
            let routeUrl = this.$router.resolve({
					name: "rankingDetail",
					query: {
						id: id
					}
				});
				window.open(routeUrl.href, '_blank');


        },
    },
    //使用keepAlive缓存组件状态，页面切换路由会触发 activated 钩子函数。在 activated 中将 之前记录的位置赋值给引起滚动条滚动元素的 scrollTop
    activated(){
        this.$nextTick(() => {
            
            this.$parent.routerIndex = 6;
            this.$parent.showIndex = 1;
            if(localStorage.getItem('clearAll') != 2){
                this.page = 1;
                if(this.$refs.page){
                    this.$refs.page.currentPage = 1;
                }
                this.listFun()
            }
            localStorage.removeItem('clearAll')
            // setTimeout(() => {
            //     document.documentElement.scrollTop = this.scroll;
            // }, 0);
        });
    }
};
</script>

<style scoped>
.ranking {
    background-color: #f6f6f6;
}

.material_screen {
    width: 100%;
    background: #ffffff;
    padding-bottom: 30px;
}

.inner_nav {
    padding: 27px 0 17px;
}
.inner_nav_l {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #999999;
}
.inner_nav_r {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #999999;
}
.inner_nav_r span {
    color: #5957FF;
}


.inner_banner img {
    width: 100%;
}




.list{
    padding-bottom: 59px;
}
.ranking_ul{
    margin: 28px -13.33px 26px;
}
.daily_li{
    width: 330px;
    height: 263px;
    border-radius: 10px;
    margin:0 13.33px 30px;
    padding: 14px 15px 0;
    box-sizing: border-box;
    background-color: #FFF;
}
.ranking_li_box{
    width: 100%;
    height: 198px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
.ranking_li_box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
}
.ranking_li_name{
    font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 51px;
	letter-spacing: 0px;
	color: #333333;
    width: 100%;
      overflow: hidden;
       /* 第二步：让文本不会换行， 在同一行继续 */
       white-space: nowrap;
       /* 第三步：用省略号来代表未显示完的文本 */
       text-overflow: ellipsis;
}

.daily_li:hover .ranking_li_box img{
    transform: scale(1.08)
}

/* @media screen and (max-width: 1600px) {
        .project_li,.project_li_box,.video_li_box,.ranking_li_box{
            width: 280px;
            height: 184.4px;
        }
        .project_li{
            background-size: 261px auto;
        }
        .entrance_nav_li{
            width: 280px;
        }
        .provide_li{
            width: 270px;
        }
        .member_box{
            width: 580px;
        }
    }
    @media screen and (max-width: 1400px) {
        .project_li,.project_li_box,.video_li_box,.ranking_li_box{
            width: 240px;
            height: 158px;
        }
        .project_li{
            background-size: 221px auto;
        }
        .entrance_nav_li{
            width: 240px;
        }
        .provide_li{
            width: 230px;
        }
        .member_box{
            width: 500px;
        }
    } */

.sidebar_r{
    right: 2%;
    left: auto;
}
</style>